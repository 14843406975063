import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { MDXRenderer } from "gatsby-plugin-mdx"
import Disqus from '../components/disqus';


export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const { mdx } = data; // data.mdx holds your post data
    const { frontmatter, body } = mdx;

    return (
        <Layout>
            <SEO title={`${frontmatter.title} - Blog`}
                 description={frontmatter.description}
                 keywords={frontmatter.tags} />
            <article className="post">
                <header className="post__header">
                    <h1>{frontmatter.title}</h1>
                    <div className="post__date-and-author">
                        <time dateTime={frontmatter.date}>{frontmatter.formattedDate}</time>
                        {' '}by Rinat Silnov
                    </div>
                </header>
                <MDXRenderer>{body}</MDXRenderer>
                <div className="post__footer post-tags">
                    {frontmatter.tags.map(tag => (
                        <span className="post-tags__tag" key={tag}>{tag}{' '}</span>
                    ))}
                </div>
            </article>
            <Disqus/>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date,
        formattedDate: date(formatString: "MMM D, YYYY"),
        slug
        title,
        tags,
        description
      }
    }
  }
`;
