import React from "react"

function Disqus() {

    return (
        <>
          <div id="disqus_thread"></div>
          <div dangerouslySetInnerHTML={{__html: `<script type="text/javascript">
    /* * * CONFIGURATION VARIABLES: EDIT BEFORE PASTING INTO YOUR WEBPAGE * * */
    var disqus_shortname = 'rinatio'; // required: replace example with your forum shortname

    /* * * DON'T EDIT BELOW THIS LINE * * */
    (function () {
    var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
    dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
  })
  ();
</script>`}}/>
          <noscript>Please enable JavaScript to view the <a href="http://disqus.com/?ref_noscript">comments powered by
            Disqus.</a></noscript>
          <a href="http://disqus.com" className="dsq-brlink">comments powered by <span
            className="logo-disqus">Disqus</span></a>
        </>
    )
}

Disqus.defaultProps = {
};

Disqus.propTypes = {
};

export default Disqus
